import { compareStrings } from "../../utils/compare.utils";

export enum RtgAgency {
    Moodys = "Moodys",
    SnP = "SnP",
    Fitch = "Fitch",
    KBRA = "KBRA",
    DBRS = "DBRS",
}

export const RtgAgencyList = [
    { value: RtgAgency.Moodys, text: "Moody’s" },
    { value: RtgAgency.SnP, text: "S&P" },
    { value: RtgAgency.Fitch, text: "Fitch" },
    { value: RtgAgency.KBRA, text: "KBRA" },
    { value: RtgAgency.DBRS, text: "DBRS" },
].sort((a, b) => compareStrings(a.text, b.text));
